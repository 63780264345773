import { Action, VerifyEmailOtpError, RedirectType } from '../types';

export interface State {
  email: string;
  isLoading: boolean;
  error?: VerifyEmailOtpError;
  redirectTo?: string;
  redirectType: RedirectType;
}

export const defaultState: State = {
  email: '',
  isLoading: false,
  redirectType: RedirectType.Signup,
};

const verifyEmailOtp = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case 'VERIFY_EMAIL_OTP_REQUEST':
      return {
        ...state,
        isLoading: true,
        error: undefined,
        redirectTo: undefined,
      };
    case 'VERIFY_EMAIL_OTP_SUCCESS':
      return {
        ...state,
        redirectTo: action.redirectTo,
      };
    case 'VERIFY_EMAIL_OTP_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default verifyEmailOtp;
